// Override default variables before the import
$body-bg: transparent;
$primary: #007d32;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.k-grid td {
  padding: 0.25em 0.5em;
}

.k-textbox {
  &:focus {
    border-color: #80be99;
    box-shadow: 0 0 0 0.25rem rgba(0, 125, 50, 0.25);
  }
}
